<template>
    <v-container>
        <DlgMessage ref="dlgMsg" @dlgClose="dlgClose"></DlgMessage>
        <DlgImageMedia ref="dlgImageMedia" :visible="dlgVisibleImageMedia" :route_type="cpFiles.route_type" :section="dlgImageMediaSection" @close="dlgImageMediaClose"></DlgImageMedia>
        <upload-files :visible="cpFiles.showDialog" :file_type="cpFiles.file_type" :route_type="cpFiles.route_type" :caller="caller" @upload-done="uploadDone" @close="cpFiles.showDialog=false"></upload-files>

        <div class="mb-3">
          <v-toolbar>
            <v-btn icon class="hidden-xs-only" @click="$router.back()"><v-icon>mdi-arrow-left</v-icon></v-btn>
            <v-toolbar-title>Supplier Detail</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-breadcrumbs :items="items"></v-breadcrumbs>
          </v-toolbar>
        </div> 
            <v-toolbar dark class="panel1"><v-toolbar-title>Info</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="save"><v-icon>save</v-icon></v-btn>
            </v-toolbar>             
        <v-row class="mt-3">
           <v-col cols="12" md="12">
                <v-card class="mb-6">
                    <div class="d-flex flex-no-wrap">
                        <v-avatar class="ma-3" size="125" tile>
                            <v-img v-if="form.image_file_name" :src="this.form.file_url + this.form.image_file_name"></v-img>
                            <v-img v-else src="../../assets/image/no-image.png"></v-img>
                        </v-avatar>
                        
                        <div>
                            <v-card-title class="headline">Logo</v-card-title>
                            <v-card-subtitle>{{ form.image_file_name}}</v-card-subtitle>
                            <v-card-actions>
                                <v-btn class="ml-2 mt-5 btnUpload" dark @click="showUpload('form.image_file_name')"><v-icon>mdi-plus</v-icon>Upload</v-btn>
                                <v-btn class="ml-2 mt-5 btnLibrary" dark @click="showDlgImageMedia('')"><v-icon>mdi-folder-image</v-icon>Library</v-btn>

                            </v-card-actions>
                        </div>
                    </div>
                </v-card>               
                <v-card>
                <v-form ref="mainForm">
                    <v-container class="pt-2">
                        <v-row>
                            <v-col cols="12" md="12">
                                
                                
                                <v-text-field outlined v-model="form.supplier_id" disabled label="ID"></v-text-field>
                                <v-text-field outlined v-model="form.supplier_code" label="Code"></v-text-field>
                                <v-text-field outlined v-model="form.supplier_name" label="Name"></v-text-field>
                                <v-textarea outlined v-model="form.long_description" label="Description" rows="10"></v-textarea>
                                <v-text-field outlined v-model="form.website" label="Website"></v-text-field>
                                <v-select
                                    outlined
                                    v-model="form.supplier_type_code"
                                    :items="supplierTypeList"
                                    item-text="description"
                                    item-value="code"
                                    label="Type"
                                    standard
                                ></v-select>                            
                                <v-select
                                    outlined
                                    v-model="form.supplier_category_code"
                                    :items="supplierCategoryList"
                                    item-text="description"
                                    item-value="code"
                                    label="Category Type"
                                    standard
                                ></v-select>                                
                                <v-select
                                    outlined
                                    v-model = "form.status"
                                    :items="status_list"
                                    item-text="description"
                                    item-value="code"
                                    label="Status"
                                    standard
                                ></v-select>
                        <v-text-field outlined v-model="form.address1" label="Address Line-1"></v-text-field>
                        <v-text-field outlined v-model="form.address2" label="Address Line-2"></v-text-field>
                        <v-text-field outlined v-model="form.address3" label="Address Line-3"></v-text-field>
                        <v-text-field outlined v-model="form.city" label="City"></v-text-field>
                        <v-text-field outlined v-model="form.postal_code" label="Postal Code"></v-text-field>
                        <v-select
                                label="State"
                                outlined
                                :items="stateList"
                                item-text="description"
                                item-value="code"
                                v-model="form.state"
                            ></v-select>

                            </v-col>
                        </v-row>

                    </v-container>
                </v-form>
                </v-card>


            </v-col>

        </v-row>

    </v-container>  
</template>
    
<script>
import DlgMessage from '@/components/DlgMessage'
import DlgImageMedia from '@/components/DlgImageMedia'
import ApSupplierService from "@/services/ApSupplierService"
import UploadFiles from "@/components/UploadFiles";
export default {
  components:{DlgMessage,UploadFiles,DlgImageMedia},
  props: {
       caller: String
    },
  data() {
    return{
            //breadcrumb
            items: [
              {text:'Catalogue',disabled: false,href:'/catalogue',},
              {text:'Supplier',disabled: false,href:'#',},
              {text:'Detail',disabled: false,href:'#',},
              ],
            status_list:[],
            supplierTypeList:[],
            supplierCategoryList:[],
            stateList:[],
           
            form:{login_id:null},
            logo:'',

            cpFiles:{
                file_type:'image',
                route_type:'suppliers',
                showDialog:false,
            },
            dpicker:{menu1: false,menu2: false,},
            dlgVisibleImageMedia:false,           
            dlgImageMediaSection:"",


    }
  },
  async created() {
      this.form.supplier_id = this.$route.params.supplier_id;
      this.viewDetail();
  },
  
  methods:{

    async viewDetail(){
        try{
            ApSupplierService.view_supplier(this.form).then((res) => {
                this.form = res.data.data;
                for (const [key, value] of Object.entries(this.form)) {
                    if(value == null){
                        this.form[key] = "";
                    }
                }                
                console.log(res.data);
                this.status_list = res.data.statusList; 
                this.supplierCategoryList = res.data.supplierCategoryList; 
                this.supplierTypeList = res.data.supplierTypeList;                 
                this.stateList = res.data.stateList; 
                
            }).catch((e)=>{
                console.log(e.response);
                this.$refs.dlgMsg.showDlgBox(true,'error','Supplier',e.response.data.error_message);
            })
            ;
        } catch(e) {
            console.log(e);
            //this.$refs.dlgMsg.showDlgBox(true,'error','Supplier','Unknown Error');
        }
    },
    async save(){

        if(this.$refs.mainForm.validate()) {
            try{
                ApSupplierService.update_supplier(this.form).then(() => {
                    this.$refs.dlgMsg.showDlgBox(true,'success','Update Supplier','Success.');
                }).catch((e)=>{
                    console.log(e.response);
                    this.$refs.dlgMsg.showDlgBox(true,'error','Supplier',e.response.data.error_message);
                })
                ;
            } catch(e) {
                this.loadingFlag = false
                this.$refs.dlgMsg.showDlgBox(true,'error','Supplier','Unknown Error');
            }            
        }            

    },
    
    uploadDone(e){
        //console.log(e);
        this.form.file_url = e.fileUrl;
        console.log("this." + e.caller + "='" + e.fileName + "'");
        eval("this." + e.caller + "='" + e.fileName + "'");
        //this.form.logo_file_name = e.fileName;
        //console.log(e.caller);
    },

    showUpload(uploadType){
        console.log('showupload');
        this.cpFiles.file_type = 'image';
        this.cpFiles.route_type = 'suppliers';
        this.caller = uploadType;
        //console.log(uploadType)
        this.cpFiles.showDialog = true;
    },
    dlgClose(){
        this.visible = false;        
    },

    dlgImageMediaClose(selectedImage){
        this.dlgVisibleImageMedia = false;

        if(selectedImage.imageFileName && selectedImage.section == ""){
            this.form.image_file_name = selectedImage.imageFileName
            this.form.file_url = selectedImage.fileUrl
            return
        }

        // this.form[selectedImage.section] = selectedImage.imageFileName;
        // this.form.file_url = selectedImage.fileUrl;
        // if(selectedImage.imageFileName && selectedImage.section =="items"){
        //     let e = {fileName:selectedImage.imageFileName,urlPreview:selectedImage.fileUrl}
        //     let totalImage = this.ciImages.length;
        //     let ciImageNew = {sequence:totalImage+1*10,file_url:e.urlPreview,image_file_name:e.fileName,preview:"",status:"ACTIVE"}
        //     this.ciImages.push(ciImageNew)
        //     this.saveIndicator = "(*)"
        //     console.log(ciImageNew);

        // }
    },
    showDlgImageMedia(section){
        this.dlgImageMediaSection = section;
        this.dlgVisibleImageMedia = true;
        
        
    },    

  }
}
</script>